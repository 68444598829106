import React from "react"
import './Footer.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function Footer() {
    return (
        <Container className="pt-5">
            <footer className=" footer py-2 mt-4" style={{paddingRight: 250, paddingLeft: 250}}>
                {/*<div className="border-top w-100"></div>*/}
                <ul className="nav border-bottom justify-content-center py-2 mb-3">
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Home</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Features</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Pricing</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">FAQs</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">About</a></li>
                </ul>
                <p className="text-center text-muted">© 2023 FinMates, Inc</p>
            </footer>
        </Container>

    );
}

export default Footer;
