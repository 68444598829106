import axios, {AxiosError} from "axios";
import toast from "react-hot-toast";
import {IUser, IUserRegistrationFormDTO} from "../model/User";
import {Router, useNavigate} from "react-router-dom";
import axiosClient from "./axios/AxiosClient";


const BASE_URL = process.env.REACT_APP_BASE_URL;

const displayToast = (message: string) => toast.success(message, {position: "top-right"});

class AuthService {

    async login(username: string, password: string) {

        try {
            await axiosClient
                .post(BASE_URL + "/auth/login", {
                    username,
                    password
                })
                .then(response => {
                    localStorage.setItem("access_token", JSON.stringify(response.data?.access_token));
                    window.location.href = '/profile'
                });
        } catch (error: any) {
            // this failed, so let's redirect to the login page
            console.log("Error login : " + error);
            if (error.data.message == 'HTTP 401 Unauthorized') {
                toast.error('Username and/or password is incorrect')
            }
        }

    }

    logout() {
        //const navigate = useNavigate();
        localStorage.removeItem("access_token");
        //navigate("/");
    }

    async register(user: IUserRegistrationFormDTO) {

        await axiosClient
            .post(BASE_URL + "/auth/user/create", user)
            .then(() =>
                window.location.href = '/profile'
            )
            .catch(error => {
                if (error.status === 409) {
                    toast.error("User with this email address is already registered in the system.")
                    return false;
                }
                    console.log("Registration error: " + error.data.message)
                    return Promise.reject(error.data.message)
                }
            )



    }

    getCurrentUser() {
        const userStr = localStorage.getItem("user");
        if (userStr) return JSON.parse(userStr);

        return null;
    }

    isLoggedIn() {
        if (localStorage.getItem('access_token')) {
            return true;
        }
        return false;
    }

}

export default new AuthService();
