import {Link, useNavigate} from "react-router-dom";
import React from "react";
import LayoutEmpty from "../layout/LayoutEmpty";
import Col from "react-bootstrap/Col";

export default function NotFound() {

    const navigate = useNavigate();

    return (
        <LayoutEmpty>
            <Col className="">
                <div className="text-center ">
                    <h1 className="display-1 mb-3"><b>404</b></h1>
                    <h2 className="display-5 mb-3">Oops! You seem to be lost.</h2>
                    <p>Here are some helpful links:</p>
                    <div className="d-flex flex-column flex">
                        <Link to='/'>Home</Link>
                        <Link to='/'>Back</Link>
                    </div>
                </div>
            </Col>
        </LayoutEmpty>
    )
}
