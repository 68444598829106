import React from "react";
import Card from "react-bootstrap/Card";
import LayoutPublic from "../../layout/LayoutPublic";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";


const Homepage = () => {
    return (
        <>
            <LayoutPublic>

                <div className="jumbotron">
                    <div className="container">
                        <h1 className="display-3">Hello, world!</h1>
                        <p>This is a template for a simple marketing or informational website. It includes a large
                            callout called a jumbotron and three supporting pieces of content. Use it as a starting
                            point to create something more unique.</p>
                        <p><a className="btn btn-primary btn-lg" href="#" role="button">Learn more »</a></p>
                    </div>
                </div>

                <Container className="main-container">
                    <Row>
                        <Card>
                            <Card.Body>
                                <h2>Home</h2>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>

            </LayoutPublic>
        </>
    );
};

export default Homepage;
