import axios, {AxiosError} from "axios";
import {IStock} from "../../model/Stock";

const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;

class StockService {

    async getBySymbol(symbol: string) {
        return axios.get<IStock>(STOCKS_URL + "/stock/symbol/" + symbol);
    }

    async getPrice(symbol: string) {
        return await axios.get(STOCKS_URL + "/stock/" + symbol + "/price");
    }

}

export default new StockService();