import React, {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card';
import {Button, Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import * as yup from 'yup';
import {Formik} from 'formik';
import InputGroup from "react-bootstrap/InputGroup";
import {PatternFormat} from 'react-number-format';
import {UserAccount} from "../../../model/User";


function AccountSettings() {


    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    async function validateUsername(value: string) {
        try {
            const response = await axios.post(
                "http://localhost:8080/validate-username",
                value,
                {headers: {"Content-Type": "text/plain"}}
            )
                .then((resp) => resp.data);

            console.log(response);
            return Boolean(response);
        } catch (error) {
            console.error(error);
        }
    }

    const response = validateUsername("sigachev").then(x => {
        return x
    });

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("First name is required"),
        lastName: yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Last name is required"),
        middleName: yup.string(),
        username: yup.string()
            .min(5, "Too Short! (minimum size is 5)")
            .max(50, "Too Long!")
            .test(
                'username-backend-validation',
                'Username taken',

                async (value: string | undefined) => {
                    const response = await axios.post(
                        "http://localhost:8080/validate-username",
                        value,
                        {headers: {"Content-Type": "text/plain"}}
                    )
                        .then((resp) => resp.data);

                    console.log(response);
                    return Boolean(response);
                }
            )
            /*.matches(/^[^@]+$/, 'Should not contain @')*/
            .required("Username is required"),
        birthdate: yup.string().required("Birthdate is required"),
        /*email: yup.string().required().email('Not a proper email')*/
        email: yup.string().required("Required").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Please enter a valid email"),
        phone: yup.string(),
    });

    const [initialValues, setInitialValues] = useState<UserAccount>();

    useEffect(() => {
        getInitialValues()
            .then((resp) => {
                setInitialValues(resp)
            })
    }, []);

    async function getInitialValues() {
        try {
            const response = await axios.get<UserAccount>('http://localhost:8080/user/2', {
                headers: {Accept: "application/json"}
            })
                .then((resp) => resp.data);

            console.log(typeof response);

            return response;

        } catch (error) {
            console.error(error);
        }
    }


    /*    const handleonSubmit = async (values: typeof initialValues) => {
            try {
                console.log(values)
                alert("Form is validated and in this block api call should be made...");
                await axios.post('/api/contact', values);
                alert('Message sent!');
            } catch (error) {
                console.error(error);
                alert('An error occurred while sending your message. Please try again later.');
            }
        };*/


    return (
        <>
            <Card className="mb-4">
                <Card.Header className="border-0 pb-0" style={{background: "white"}}>
                    <Card.Title className="h5">Account Settings</Card.Title>
                    <Card.Text className="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all
                        Devonshire
                        difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</Card.Text>
                </Card.Header>
                <Card.Body>
                    {initialValues && initialValues !== undefined ?
                        <Formik
                            validationSchema={validationSchema}
                            onSubmit={console.log}
                            enableReinitialize={true}
                            initialValues={
                                initialValues
                                /*{
                                       firstName: initialValues?.firstName || 'xxx',
                                       lastName: initialValues.lastName,
                                       middleName: '',
                                       username: 'mark.otto',
                                       birthdate: '',
                                       email: 'mark.otto@gmail.com',
                                       phone: '"+16177752595"'
                                }*/
                            }
                        >
                            {({handleSubmit, handleChange, handleBlur, values, initialValues, touched, errors}) => (
                                <Form name="form2" as={Row} className="g-3" noValidate onSubmit={handleSubmit}>
                                    <Form.Group as={Col} sm={6} lg={4} controlId="validationFormik01">
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name"
                                            value={values.firstName || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur} // This apparently updates `touched`
                                            isValid={touched.firstName && !errors.firstName}
                                            isInvalid={!!errors.firstName}/>
                                        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                                        <Form.Control.Feedback
                                            type="invalid">{errors.firstName as string}</Form.Control.Feedback>
                                        {/*{errors.firstName && <div>{errors.firstName}</div>}*/}
                                        {/*{<div>init_values={initialValues as string}</div>}*/}
                                    </Form.Group>

                                    <Form.Group as={Col} sm={6} lg={4} controlId="validationFormik02">
                                        <Form.Label className="form-label">Last name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name"
                                            value={values.lastName || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur} // This apparently updates `touched`
                                            isValid={touched.lastName && !errors.lastName}
                                            isInvalid={!!errors.lastName}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.lastName as string}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm={6} lg={4} controlId="validationFormik03">
                                        <Form.Label>Middle name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="middleName"
                                            placeholder=""
                                            value={values.middleName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isValid={touched.middleName && !errors.middleName}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={6} controlId="validationFormikUsername">
                                        <Form.Label>Username</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="usernamePrepend">@</InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                name="username"
                                                placeholder="Username"
                                                aria-describedby="usernamePrepend"
                                                value={values.username}
                                                onChange={handleChange}
                                                isInvalid={!!errors.username}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errors.username as string}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} lg={6} controlId="validationFormik05">
                                        <Form.Label className="form-label">Birthday</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="birthdate"
                                            className="flatpickr flatpickr-input"
                                            value={values.birthdate}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isValid={touched.birthdate && !errors.birthdate}
                                        />
                                    </Form.Group>

                                    <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                   id="allowChecked"/>
                                            <label className="form-check-label" htmlFor="allowChecked">
                                                Allow anyone to add you to their team
                                            </label>
                                        </div>
                                    </div>

                                    <Form.Group as={Col} sm={6} controlId="validationFormikPhone">
                                        <Form.Label>Phone number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phone"
                                            placeholder="+1 (123) 456 7890"
                                            as={PatternFormat}
                                            format={"+# (###) ### ## ##"}
                                            mask={"_"}
                                            value={values.phone}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.phone}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.phone as string}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm={6} lg={4} controlId="validationEmailFormik">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur} // This apparently updates `touched`
                                            isValid={touched.email && !errors.email}
                                            isInvalid={!!errors.email}/>
                                        <Form.Control.Feedback
                                            type="invalid">{errors.email as string}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Overview</Form.Label>
                                        <Form.Text className="form-control" placeholder="Description (Required)"
                                                   style={{height: 100}}>
                                            Interested has all Devonshire difficulty gay assistance joy. Handsome met
                                            debating sir dwelling age material.
                                            As style lived he worse dried. Offered related so visitors we private
                                            removed.
                                            Moderate do subjects to distance.</Form.Text>
                                        <small>Character limit: 300</small>
                                    </Form.Group>

                                    <Col className="col-12 text-end">
                                        <Button type="submit" variant="primary">Save changes</Button>
                                    </Col>
                                </Form>
                            )}
                        </Formik>
                        : <Button>Loading</Button>
                    }
                </Card.Body>
            </Card>


            {/*        <Card>
            <Card.Header className="border-0 pb-0" style={{background: "white"}}>
                <Card.Title>Change your password</Card.Title>
                <Card.Text className="mb-0">See resolved goodness felicity shy civility domestic had
                    but.</Card.Text>
            </Card.Header>
            <Card.Body>
                <Form className="row g-3">

                    <div className="col-12">
                        <label className="form-label">Current password</label>
                        <input type="text" className="form-control" placeholder=""/>
                    </div>

                    <div className="col-12">
                        <label className="form-label">New password</label>

                        <div className="input-group">
                            <input className="form-control fakepassword" type="password" id="psw-input"
                                   placeholder="Enter new password"/>
                            <span className="input-group-text p-0">
                          <i className="fakepasswordicon fa-solid fa-eye-slash cursor-pointer p-2 w-40px"></i>
                        </span>
                        </div>

                        <div id="pswmeter" className="mt-2 password-strength-meter">
                            <div className="password-strength-meter-score"></div>
                        </div>
                        <div id="pswmeter-message" className="rounded mt-1">Write your password...</div>
                    </div>

                    <div className="col-12">
                        <label className="form-label">Confirm password</label>
                        <input type="text" className="form-control" placeholder=""/>
                    </div>

                    <div className="col-12 text-end">
                        <button type="submit" className="btn btn-primary mb-0">Update password</button>
                    </div>
                </Form>
            </Card.Body>
        </Card>*/}
        </>
    );
}

export default AccountSettings;
