import React from "react";
import Layout from "../layout/Layout";
import Card from "react-bootstrap/Card";


const Home = () => {
    return (
        <>
            <Layout>
                <Card>
                    <Card.Body>
                        <h2>Home</h2>
                    </Card.Body>
                </Card>
            </Layout>
        </>
    );
};

export default Home;
