import React from "react";
import {Outlet} from "react-router";
import SignIn from "../sign-in/SignIn";
import {Navigate} from "react-router-dom";
import AuthService from "../../service/AuthService";


const useAuth = () => {
    const user = {loggedIn: false};
    return user && user.loggedIn;
};

const ProtectedRoutes = () => {
    const isAuth = AuthService.isLoggedIn();
    return isAuth ? <Outlet/> : <Navigate to="/sign-in"/>;
};

export default ProtectedRoutes;
