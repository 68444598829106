import React from 'react';
import './App.scss';
import {Route, Routes} from 'react-router-dom';
import Home from "./components/home/Home";
import Stock from "./components/stock/Stock";
import Profile from "./components/profile/Profile";
import SignUp from "./components/sign-up/SignUp";
import SignIn from "./components/sign-in/SignIn";
import {Toaster} from "react-hot-toast";
import NotFound from "./components/error-pages/NotFound";
import ProtectedRoutes from "./components/protected/ProtectedRoutes";
import Stocks from "./components/Stocks";
import Homepage from "./components/public/homepage/Homepage";

function App() {


    return (
        <>

            <Toaster/>
            <div style={{background: '#EFF2F7'}}>
                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route element={<ProtectedRoutes/>}>

                        <Route path='/profile' element={<Profile/>}></Route>
                    </Route>
                    <Route path='/stock/:symbol?' element={<Stock/>}></Route>
                    <Route path='/home' element={<Homepage/>}></Route>
                    <Route path='/stocks' element={<Stocks/>}></Route>
                    <Route path='/sign-up' element={<SignUp/>}></Route>
                    <Route path='/sign-in' element={<SignIn/>}></Route>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </div>
        </>
    );
}

export default App;
