import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import './Header.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Button, Form, NavDropdown} from "react-bootstrap";
import AuthService from "../../../service/AuthService";
import {Link, useNavigate} from "react-router-dom";
import {CgRowFirst} from "react-icons/cg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


export default function Header() {
    const navigate = useNavigate();


    function logout() {
        AuthService.logout();
        navigate("/")
    }


    return (

        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container className="justify-content-center">

                <Col className="col-1-5 d-flex align-items-center">
                    <img src="/icons/logo.png" width={33}/>&nbsp;&nbsp;
                    <Navbar.Brand className="vert" href="/">FinMates</Navbar.Brand>
                </Col>

                <Col className="col-1-5"></Col>

                <Col className="col-6 d-flex justify-content-center">
                    {/*<Nav className="ms-1 me-auto w-50">*/}
                    <Nav>
                        <Form style={{width: 375}}>
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                className="bg-light "
                                aria-label="Search"
                            />
                        </Form>
                    </Nav>
                </Col>


                <Col className="col-3 d-flex align-items-end">

                    <Nav className="ms-auto">

                        {AuthService.isLoggedIn() ?
                            <>
                                <Navbar.Toggle aria-controls="navbar-dark-example"/>
                                <Navbar.Collapse id="navbar-dark-example" className="justify-content-end">
                                    <Nav>
                                        <NavDropdown
                                            id="nav-dropdown-dark-example"
                                            title="My Account"
                                            menuVariant="dark"
                                            align="end"
                                        >
                                            <NavDropdown.Item href="/account/settings">Settings</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.2">
                                                Another action
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                            <NavDropdown.Divider/>
                                            <NavDropdown.Item onClick={logout}>
                                                Sign Out
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav>
                                </Navbar.Collapse>
                            </>
                            :
                            <>

                                    <Nav.Link href="/faq">FAQs</Nav.Link>
                                    <Nav.Link className="me-2" eventKey={2} href="#memes">
                                        About
                                    </Nav.Link>
                                    <a href="sign-in">
                                        <Button variant="outline-light"
                                                className=" me-2 my-lg-0 my-sm-2 text-nowrap"> Login</Button>
                                    </a>
                                    <a href="sign-up">
                                        <Button variant="primary"
                                                className=" me-2 my-lg-0 my-sm-2 text-nowrap"> Sign-up</Button>
                                    </a>

                            </>
                        }


                    </Nav>

                </Col>

                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            </Container>
        </Navbar>

    );
}
/*
export default Header;*/
