import Layout from "../layout/Layout";
import React from "react";
import './profile.css';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AccountSettings from "./account/AccountSettings";
import NotificationsSettings from "./account/NotificationsSettings";

const Profile = () => {

    /*    let props: iLayout = {
            middle: "",
            right: ""
        }

        props.middle = "xxx";

        props.right = (*/

    const content = <Tab.Container id="list-group-tabs-example" defaultActiveKey="#account">
        <Row>
            <Col className="col-lg-10">
                <Tab.Content>
                    <Tab.Pane eventKey="#account"> <AccountSettings/> </Tab.Pane>
                    <Tab.Pane eventKey="#notifications"><NotificationsSettings/></Tab.Pane>
                    <Tab.Pane eventKey="#privacy">Privacy and Safety</Tab.Pane>
                    <Tab.Pane eventKey="#communications">Communications</Tab.Pane>
                    <Tab.Pane eventKey="#messaging">Messaging</Tab.Pane>
                </Tab.Content>
            </Col>

            <Col className="col-lg-2">
                <ListGroup>
                    <ListGroup.Item action href="#account">
                        Account
                    </ListGroup.Item>
                    <ListGroup.Item action href="#notifications">
                        Notifications
                    </ListGroup.Item>
                    <ListGroup.Item action href="#privacy">
                        Privacy and Safety
                    </ListGroup.Item>
                    <ListGroup.Item action href="#communications">
                        Communications
                    </ListGroup.Item>
                    <ListGroup.Item action href="#messaging">
                        Messaging
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </Row>

    </Tab.Container>


    /*    );*/

    return (
        <>
            <Layout>
                {content}
            </Layout>
        </>
    );
};

export default Profile;
