import React from 'react';
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import './layout.css';
import {TickerTape} from "react-ts-tradingview-widgets";


function Layout(props: any) {

    const {children} = props;
    return (
        <>
            <Header/>

            {children}

            <Footer/>

        </>
    )
}


export default Layout;
