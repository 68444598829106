import React from "react";
import Layout from "./layout/Layout";

export const Stocks = () => {
    return (
        <>
            <Layout>
                <h2>Stocks</h2>
            </Layout>
        </>
    );
};

export default Stocks;