import {AdvancedRealTimeChart, SymbolInfo, TickerTape} from "react-ts-tradingview-widgets";
import './stock.css';
import parse from 'html-react-parser';
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Layout from "../layout/Layout";
import Card from "react-bootstrap/Card";
import {ChartComponent} from "../chart/ChartComponent";
import axios, {AxiosResponse} from "axios";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Tab, Tabs} from "react-bootstrap";
import Stack from 'react-bootstrap/Stack';
import {RadioGroup} from "rsuite";
import StockService from "../../service/stock/StockService";
import {IStock} from "../../model/Stock";
import StockProfile from "./profile/StockProfile";


const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;

const Stock = () => {


    const {symbol} = useParams();
    /*    if (stockSymbol) {
            stockSymbol =  stockSymbol;
        }*/

    const [period, setPeriod] = useState("1d");

    const handlePeriodSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setPeriod(button.innerHTML);
        /*console.log("period = " + period);*/
    };


    function updateChart(period: string) {
        null;
    }

    const [price, setPrice] = useState([]);
    const [stock, setStock] = useState<IStock>();

    useEffect(() => {
        /*        StockService.getPrice(symbol as string).then(response => {
                    setPrice(response.data);
                    console.log("price = ", price)
                });*/
        StockService.getBySymbol(symbol as string).then((response: AxiosResponse<IStock>) => {
            setStock(response.data);
            console.log("stock = ", stock)
        })
    }, []);


    interface ITS {
        time: number;
        value: number;
    }


    const [data, setData] = useState<ITS[]>([]);


    useEffect(() => {
        axios
            .get(STOCKS_URL + "/timeseries/" + symbol + "?period=" + period, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/stream+json"
                }
            })
            .then(result => {
                    setData(result.data)
                }
            );
    }, [period]);


    const eventSource = new EventSource(STOCKS_URL + "/stream/stock/" + symbol + "/price");

/*    eventSource.onopen = (event: any) => console.log('open', event);
   eventSource.onmessage = (event: any) => {
        console.log("event data : ", event.data);
        setPrice(event.data);
    }

   eventSource.onerror = (event: any) => console.log("event error : ", event);*/
    eventSource.addEventListener("stock-price", (event) => {
        console.log("event listener data : ", event.data);
        if (event.data) {
            setPrice(event.data);
        }
    });


/*    const eventSource1 = new EventSource(STOCKS_URL + "/stock/test");
    //eventSource.onopen = (event: any) => console.log('open', event);
    eventSource1.onmessage = (event: any) => {
        console.log("event1 data : ", event.data);
        setPrice(event.data);
    }*/

    const content =
        <div className="stock-chart text-wrap mb-5 pb-5">
            <Card className="mb-3">
                <Card.Header>
                    <Card.Title>
                        <div className="float-start">
                            <div className="d-flex"><h5 className="card-title">{symbol?.toUpperCase()}</h5><span
                                className="px-2 text-primary h5">${price}</span></div>
                            <h6 className="card-subtitle mb-2 text-body-secondary">{stock?.name}</h6>
                        </div>
                        <ButtonGroup className="btn-group-sm pt-2 float-end" aria-label="Period">
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == '1h'}>1h</Button>
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == '1d'}>1d</Button>
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == '5d'}>5d</Button>
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == '1m'}>1m</Button>
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == '6m'}>6m</Button>
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == 'YTD'}>YTD</Button>
                            {/*<div className="vr"/>*/}
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == '1y'}>1y</Button>
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == '5y'}>5y</Button>
                            <Button variant="btn btn-outline-secondary" onClick={handlePeriodSelect}
                                    active={period == 'MAX'}>MAX1</Button>
                        </ButtonGroup>

                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <ChartComponent data={data}></ChartComponent>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>

                    <Tabs defaultActiveKey="summary" className="mb-3" fill>
                        <Tab eventKey="summary"
                             title="Summary">{stock?.description ? parse(stock?.description) : null}</Tab>
                        <Tab eventKey="profile" title="Profile"><StockProfile props={symbol}/></Tab>
                        <Tab eventKey="holders" title="Holders">Tab content for Holders Tab</Tab>
                        <Tab eventKey="history" title="Historical Data" disabled>Tab content for Historical
                            Data</Tab>
                    </Tabs>

                </Card.Body>
            </Card>

        </div>


    return (
        <>
            <Layout>
                {content}
            </Layout>
        </>
    );
};


export default Stock;