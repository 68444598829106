import React, {useRef} from "react";
import LayoutEmpty from "../layout/LayoutEmpty";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {Button, FloatingLabel, Form, Spinner} from "react-bootstrap";
import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import * as yup from "yup";
import {Formik} from "formik";
import AuthService from "../../service/AuthService";
import {IUser, IUserRegistrationForm, IUserRegistrationFormDTO} from "../../model/User";
import {useNavigate} from "react-router-dom";

function SignUp() {

    const displayToast = () => toast.success('Here is your toast.', {position: "top-right"});

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .max(20, "Too Long!")
            .required("First name is required"),
        lastName: yup.string().required()
            .max(20, "Too Long!")
            .required("Last name is required"),
        /*email: yup.string().required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, 'Not a proper email'),*/
        email: yup.string().required("Required").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Please enter a valid email"),
        password: yup.string().required("Password is a required field")
            .min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),
        passwordConfirmation: yup.string().required("Password Confirmation is a required field").oneOf([yup.ref("password")], "Passwords must match")
    });


    const handleSubmit1 = async (user: IUser) => {

        AuthService.register(user);
    }

    function handleRegistration(user: IUser) {
        AuthService.register(user)
        //alert(JSON.stringify(user, null, 2));
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));


    const content = (
        <>
            <Col className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                <Card className="card card-body rounded-3 p-4 p-sm-5">
                    <div className="text-center">

                        {/*<Button onClick={displayToast}>Make me a toast</Button>*/}

                        <h1 className="mb-2">Sign up</h1>
                        <span className="d-block">Already have an account? <a
                            href="sign-in">Sign in here</a></span>

                        <Formik
                            validateOnMount={true}
                            validationSchema={validationSchema}
                            onSubmit={(values, {setSubmitting, resetForm}) => {

                                // When button submits form and form is in the process of submitting, submit button is disabled
                                setSubmitting(true);
                                console.log('Submitting foerm');
                                AuthService.register(values as IUserRegistrationFormDTO)
                                    .catch(err => toast.error(err));
                                setSubmitting(false);

                            }}
                            enableReinitialize={true}
                            initialValues={
                                {
                                    firstName: 'Mikhail',
                                    lastName: 'Sigachev',
                                    email: 'sigachev.m@gmail.com',
                                    password: 'Int68593!',
                                    passwordConfirmation: 'Int68593!'
                                }
                            }

                        >
                            {({
                                  isSubmitting,
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  values,
                                  initialValues,
                                  touched,
                                  errors,
                                  isValid,
                                  submitForm
                              }) => (
                                <Form className="mt-4 mb-4 g-3 text-start" name="registrationForm"
                                      onSubmit={handleSubmit}>
                                    <Form.Group controlId="validationFormik01">
                                        <FloatingLabel
                                            label="First Name"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="firstName"
                                                size="lg"
                                                placeholder="First Name"
                                                value={values.firstName || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={touched.firstName && !errors.firstName}
                                                isInvalid={touched.firstName && !!errors.firstName}/>

                                            {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                                            <Form.Control.Feedback
                                                type="invalid">{errors.firstName as string}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        {/*{errors.firstName && <div>{errors.firstName}</div>}*/}
                                        {/*{<div>init_values={initialValues as string}</div>}*/}

                                        {/*<Form.Label className="form-label">Last name</Form.Label>*/}
                                        <FloatingLabel
                                            label="Last Name"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="lastName"
                                                size="lg"
                                                placeholder="Last Name"
                                                value={values.lastName || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={touched.lastName && !errors.lastName}
                                                isInvalid={touched.lastName && !!errors.lastName}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errors.lastName as string}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="validationFormikEmail">
                                        <FloatingLabel
                                            label="Email address"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                size="lg"
                                                placeholder="Enter email"
                                                value={values.email || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={touched.email && !errors.email}
                                                isInvalid={touched.email && !!errors.email}
                                            />
                                            {!errors.email ?
                                                <div className="text-center">
                                                    <small className="text-center">We&apos;ll never share your email
                                                        with anyone else.</small>
                                                </div>
                                                :
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.email as string}</Form.Control.Feedback>
                                            }
                                        </FloatingLabel>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="validationFormikPassword">
                                        <FloatingLabel
                                            label="Password"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="password"
                                                id="psw-input"
                                                name="password"
                                                size="lg"
                                                placeholder="Enter new password"
                                                value={values.password || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={touched.password && !errors.password}
                                                isInvalid={touched.password && !!errors.password}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errors.password as string}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="validationFormikPasswordConfirmation">
                                        <FloatingLabel
                                            label="Password confirmation"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="password"
                                                name="passwordConfirmation"
                                                placeholder="Confirm password"
                                                size="lg"
                                                value={values.passwordConfirmation || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                                                isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errors.passwordConfirmation as string}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>


                                    {/*                                    <Form.Group className="lead mb-3 text-start">
                                        <Form.Check label="&nbsp;Keep me signed in" id="keepsingnedCheck"/>
                                    </Form.Group>*/}

                                    <div className="d-grid">
                                        <Button variant="primary" size="lg" type="submit"
                                                disabled={isSubmitting || !isValid || !touched}>
                                            {isSubmitting ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="me-2"
                                                    />
                                                    Please wait...
                                                </> : "Sign Up"}
                                        </Button>

                                        {/*                                        <br/>
                                        <Button onClick={() => {
                                            console.log('Clicked')
                                        }}>Test</Button>*/}
                                    </div>

                                </Form>
                            )}
                        </Formik>

                    </div>

                </Card>
            </Col>
        </>
    );


    return (
        <>
            <LayoutEmpty>
                {content}
            </LayoutEmpty>
        </>
    );

}

export default SignUp;
