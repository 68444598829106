import React, {useEffect, useState} from "react";
import LayoutEmpty from "../layout/LayoutEmpty";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {Button, Form, Nav, Spinner} from "react-bootstrap";
import toast from "react-hot-toast";
import * as yup from "yup";
import {Formik} from "formik";
import AuthService from "../../service/AuthService";
import InputGroup from "react-bootstrap/InputGroup";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {Navigate, useLocation, useNavigate} from "react-router-dom";


function SignIn() {

    /*    type Props = {};*/

    type State = {
        redirect: string | null,
        username: string,
        password: string,
        loading: boolean,
        message: string
    };

    const initialState: State = {
        redirect: null,
        username: "",
        password: "",
        loading: false,
        message: ""
    }

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (currentUser) {
            const s = initialState;
            s.redirect = "/profile";
            setState(s);
        }

    });

    const [state, setState] = useState(initialState);
    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();

    function handleLogin(formValue: { username: string; password: string }) {
        const {username, password} = formValue;
        const s: State = initialState;
        s.loading = true;
        setState(s)
    }

    //const displayToast = () => toast.success('Here is your toast.', {position: "top-right"});

    const validationSchema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required()
    });


    const initialValues = {
        username: "",
        password: "",
    };

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    const location = useLocation();

    const navigateHome = () => {
        // 👇️ navigate to /
        navigate('/');
    };

    const content = (
        <Col className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
            <Card className="card card-body rounded-3 p-4 p-sm-5">
                <div className="text-center">
                    {/* <Button variant="primary" size="lg" type="submit" onClick={displayToast}>Sign in</Button>*/}
                    <h1 className="mb-2">Sign in</h1>
                    <span className="d-block">Do not have an account yet? <a
                        href="sign-up">Sign up here</a></span>
                    <Formik
                        validationSchema={validationSchema}
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            setSubmitting(true);

                            AuthService.login(values.username, values.password)

                            setSubmitting(false);
                        }}
                        enableReinitialize={true}
                        initialValues={initialValues}
                    >
                        {({
                              isSubmitting,
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              initialValues,
                              touched,
                              errors,

                          }) => (
                            <Form className="mt-4 mb-3" onSubmit={handleSubmit}>

                                <Form.Group className="mb-4" controlId="formUsername">
                                    <Form.Control
                                        type="username"
                                        name="username"
                                        placeholder="Enter username or email address"
                                        size="lg"
                                        value={values.username}
                                        onBlur={handleBlur} // This apparently updates `touched`
                                        onChange={handleChange}
                                        isValid={touched.username && !errors.username}
                                        isInvalid={touched.username && !!errors.username}
                                    />

                                </Form.Group>

                                <Form.Group className=" mb-4" controlId="formPassword">
                                    <InputGroup size="lg">
                                        <Form.Control
                                            type={passwordShown ? "text" : "password"}
                                            name="password"
                                            placeholder="Enter password"
                                            size="lg"
                                            value={values.password}
                                            onBlur={handleBlur} // This apparently updates `touched`
                                            onChange={handleChange}
                                            isValid={touched.password && !errors.password}
                                            isInvalid={touched.password && !!errors.password}
                                        />
                                        <InputGroup.Text id="show-password">
                                            <Nav.Link onClick={() => {
                                                setPasswordShown(!passwordShown)
                                            }}>
                                                {passwordShown ? <FaEye/> : <FaEyeSlash/>}
                                            </Nav.Link>
                                        </InputGroup.Text>

                                    </InputGroup>
                                </Form.Group>

                                <div className="lead mb-3 d-sm-flex justify-content-between">

                                    <Form.Group>
                                        <Form.Check type="checkbox" id="rememberCheck" label="Remember me?"/>
                                    </Form.Group>
                                    <a href="forgot-password">Forgot password?</a>
                                    {/*<Nav.Link href="forgot-password">Forgot password?</Nav.Link>*/}
                                </div>

                                <div className="d-grid">
                                    <Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ?
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                                Please wait...
                                            </> : "Login"}
                                    </Button>
                                    <Button className="mt-2" onClick={navigateHome}>Go Home</Button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </div>

            </Card>
        </Col>
    );


    return (
        <>
            <LayoutEmpty>
                {content}
            </LayoutEmpty>
        </>
    );

}

export default SignIn;
