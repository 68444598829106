import React from 'react';
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./sidebar/Sidebar";
import './layout.css';
import PropTypes from "prop-types";

/*class Layout extends React.Component<{ children: any }> {*/
function Layout(props: any) {

    const {children} = props;
    return (
        <>
            <Header/>

            <Container className="main-container">
                <Row>

                    <Col className=" col-1-5 px-0">
                        <Sidebar/>
                    </Col>

                    <Col className="col-10-5">
                        {children}
                    </Col>

                </Row>
            </Container>

            <Footer/>

        </>
    )
}


export default Layout;
