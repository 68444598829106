import axios from 'axios'
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const errorToast = (message: string) => toast.error(message, {position: "top-right"});

// Add a request interceptor
const axiosClient = axios.create();
axiosClient.interceptors.request.use(
    config => {
        //config.baseURL = process.env.REACT_APP_BASE_URL
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    error => {

        // Promise.reject(error)
        console.log('Axios request error : ' + error);
        //errorToast(error.message);
    }
)



axiosClient.interceptors.response.use(
    (res) =>
        res.data,
    (error) => {

        if (error.response) {
            const resp = error.response;
            console.log('Axios response error : ' + resp.status);
            if (resp.status === 401) {
                //window.location.href = '/';
                errorToast("Unauthorized request");
                return false;
            }
            //throw new Error(error.response.status);
            //errorToast(error.response.data.message);
            return Promise.reject(resp)
        }
    }
)



export default axiosClient;